/* BidChart.css */

.bid-chart-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Poppins', Arial, sans-serif !important;
}

.bid-chart-title {
  text-align: center;
  margin-bottom: 20px;
}

.bid-chart-title h2 {
  margin-bottom: 5px;
  color: #035E7B;
  font-size: 24px !important;
  font-weight: 600 !important;
}

.bid-chart-title h3 {
  margin-top: 0;
  color: #035E7Bad;
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 1.4; /* Add this line */
}

.bid-cards-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-bottom: 20px;
}

.bid-card {
  display: flex; /* Add this line */
  flex-direction: column; /* Add this line */
  justify-content: center; /* Add this line */
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  height: 100px; /* Add this line to ensure equal height */
}

.bid-label {
  font-size: 14px;
  margin-bottom: 5px;
  color: #002E2C !important;
}

.bid-value {
  font-size: 18px;
  font-weight: bold;
  color: #035E7B !important;
}

@media (max-width: 480px) {
  .bid-chart-container {
    padding: 10px;
  }

  .bid-card {
    padding: 8px;
    height: auto; /* Add this line for responsive design */
  }

  .bid-label {
    font-size: 12px;
  }

  .bid-value {
    font-size: 16px;
  }
}
